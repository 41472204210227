.bottom-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;

  &__text {
    color: white;
    margin: 0;
    padding-top: 5%;
    padding-bottom: 3%;
    background: linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,1));
    text-align: center;
    text-transform: none;
    font-weight: normal;
    font-size: 1.8rem;
  }
}
