.ReactModal__Content {
  font-size: 2rem;
  text-align: justify;

  button {
    font-size: 1.95rem;

    .material-symbols-outlined {
      font-size: inherit;
      vertical-align: text-top;
    }
  }
}
