.brand-selector {
  display: flex;

  &__option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: calc(100% / 3);
    padding: 4% 1.5%;
    background-color: rgb(1,1,1);
    transition: background-color 0.3s linear, box-shadow 0.2s linear;

    img {
      width: 100%;
      height: auto;
      opacity: 0.5;
      transition: opacity 0.3s linear;
    }

    &--active {
      background-color: rgb(8,8,8);
      box-shadow: black 0px 22px 22px 5px;
      z-index: 2;

      img {
        opacity: 1;
      }
    }
  }
}
