@use 'sass:color';

// 1. ABSTRACTS

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

// 2. VENDORS

@import 'vendors';

// 3. VENDORS EXTENSIONS

// 4. BASE

@import 'base/typography';
@import 'base/base';

// 5. LAYOUT

// 6. COMPONENTS

@import 'components/camera-mark';
@import 'components/floating-buttons';
@import 'components/modal';
@import 'components/bottom-container';
@import 'components/brand-selector';
@import 'components/countdown';
@import 'components/language-selector';

// 7. PAGES

@import 'pages/home';
