@keyframes fade-out-up {
  0% {
    transform: translateY(50px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50px);
  }
}

.camera-mark {
  position: fixed;
  color: var(--highlight-color);
  top: 0;
  right: 16%;
  animation: fade-out-up 1s ease infinite;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .material-symbols-outlined {
    font-size: 15rem;
  }
}
