.floating-button-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0% 3% 3% 3%;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));

  .bottom-container--home & {
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.9));
  }

  .material-symbols-outlined {
    font-size: inherit;
    vertical-align: text-top;
  }
}
