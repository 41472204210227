*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

html {
  font-size: 12px;
}

@media only screen and (min-width: 900px) {
  html {
    font-size: 16px;
  }
}

body {
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 500;
}

h2 {
  text-align: center;
  margin: .1em 0;
  font-size: 3.2em;
}

html {
  --base-bg-color: #f5f5f5;
  --font-color: white;
  --bg-color: #21273a;
  --highlight-color: #d69c47;
  color: var(--font-color);
  background-color: var(--base-bg-color);
  -webkit-user-select: none;
  user-select: none;
}

button {
  text-transform: inherit;
  box-sizing: border-box;
  text-align: center;
  border: .15em solid var(--font-color);
  color: var(--bg-color);
  background-color: var(--font-color);
  border-radius: 3em;
  margin: 0 .4em .4em 0;
  padding: .7em 1.3em;
  font-size: 2.7rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 -.6em 0 -.35em #0000002b;
}

button.primary {
  color: var(--font-color);
  background-color: var(--bg-color);
  border: .15em solid var(--font-color);
}

button:disabled {
  color: #00000059;
  background-color: #d3d3d3;
}

button:active {
  top: .1em;
}

button.selected {
  color: var(--bg-color);
  background-color: var(--font-color);
  border-color: #000;
}

input {
  font-family: inherit;
  font-weight: inherit;
  text-transform: initial;
  width: 100%;
  border: none;
  border-radius: 3em;
  padding: .7em 1.4em;
  font-size: 2rem;
}

#__parcel__error__overlay__ {
  text-transform: initial;
}

@keyframes fade-out-up {
  0% {
    transform: translateY(50px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(50px);
  }
}

.camera-mark {
  color: var(--highlight-color);
  text-shadow: 0 5px 15px #00000059;
  animation: fade-out-up 1s infinite;
  position: fixed;
  top: 0;
  right: 16%;
}

.camera-mark .material-symbols-outlined {
  font-size: 15rem;
}

.floating-button-bar {
  background: linear-gradient(#0000, #00000080);
  flex-direction: row;
  justify-content: center;
  padding: 0% 3% 3%;
  display: flex;
}

.bottom-container--home .floating-button-bar {
  background: linear-gradient(#0000, #000000e6);
}

.floating-button-bar .material-symbols-outlined {
  font-size: inherit;
  vertical-align: text-top;
}

.ReactModal__Content {
  text-align: justify;
  font-size: 2rem;
}

.ReactModal__Content button {
  font-size: 1.95rem;
}

.ReactModal__Content button .material-symbols-outlined {
  font-size: inherit;
  vertical-align: text-top;
}

.bottom-container {
  z-index: 2;
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottom-container__text {
  color: #fff;
  text-align: center;
  text-transform: none;
  background: linear-gradient(#000000e6, #000);
  margin: 0;
  padding-top: 5%;
  padding-bottom: 3%;
  font-size: 1.8rem;
  font-weight: normal;
}

.brand-selector {
  display: flex;
}

.brand-selector__option {
  background-color: #010101;
  flex-basis: 33.3333%;
  justify-content: center;
  align-items: center;
  padding: 4% 1.5%;
  transition: background-color .3s linear, box-shadow .2s linear;
  display: flex;
}

.brand-selector__option img {
  width: 100%;
  height: auto;
  opacity: .5;
  transition: opacity .3s linear;
}

.brand-selector__option--active {
  z-index: 2;
  background-color: #080808;
  box-shadow: 0 22px 22px 5px #000;
}

.brand-selector__option--active img {
  opacity: 1;
}

.countdown {
  color: var(--highlight-color);
  width: 10rem;
  text-align: center;
  text-shadow: 3px 0 10px #000;
  font-size: 10rem;
  position: fixed;
  top: 8%;
  left: calc(50% - 5rem);
}

.language-selector {
  z-index: 3;
  position: absolute;
  bottom: 33rem;
  left: 50%;
  transform: translateX(-50%);
}

.cover-container {
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.logo {
  text-align: center;
  padding: 3vh 0;
}

.logo svg {
  width: 50%;
  fill: #fff;
}

#frame {
  width: 100%;
  z-index: 1;
  opacity: .1;
  position: absolute;
  inset: 0;
}

#video, #canvas, #photo {
  width: 100%;
  height: auto;
}

#canvas {
  outline: 1px #00f;
}

/*# sourceMappingURL=index.a2fef546.css.map */
