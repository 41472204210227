.cover-container {
  position: absolute;
  inset: 0;
  margin: auto;
  overflow: hidden;
}

.logo {
  text-align: center;
  padding: 3vh 0;

  svg {
    width: 50%;
    fill: white;
  }
}

#frame {
  position: absolute;
  inset: 0;
  width: 100%;
  z-index: 1;
  opacity: 0.1;
}

#video, #canvas, #photo {
  width: 100%;
  height: auto;
  // z-index: -1;
  // display: block;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // width: auto;
  // height: 100vh;
  // pointer-events: none;
  // margin: 0 auto;
}
#canvas {
  outline: 1px blue;
}
