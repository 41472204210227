.countdown {
  color: var(--highlight-color);
  font-size: 10rem;
  position: fixed;
  top: 8%;
  left: calc(50% - 10rem/2);
  width: 10rem;
  text-align: center;
  text-shadow: black 3px 0 10px;
}
