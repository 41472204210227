html {
  font-size: 12px;

  @media only screen and (min-width: 900px) {
    font-size: 16px;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 32px;
  text-transform: uppercase;
}

h2 {
  text-align: center;
  font-size: 3.2em;
  margin: 0.1em 0;
}
