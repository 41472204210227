html {
  --base-bg-color: #f5f5f5;
  --font-color: white;
  --bg-color: #21273A;
  --highlight-color: #d69c47;

  color: var(--font-color);
  background-color: var(--base-bg-color);
  user-select: none;
}

button {
  font-size: 2.7rem;
  text-transform: inherit;
  display:inline-block;
  padding:0.7em 1.3em;
  margin:0 0.4em 0.4em 0;
  border-radius: 3em;
  box-sizing: border-box;
  box-shadow:inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);
  text-align:center;
  position:relative;
  font-weight: 600;
  border: 0.15em solid var(--font-color);
  color: var(--bg-color);
  background-color: var(--font-color);

  &.primary {
    color: var(--font-color);
    background-color: var(--bg-color);
    border: 0.15em solid var(--font-color);
  }

  &:disabled {
    color: rgba(0,0,0,0.35);
    background-color: lightgrey;
  }

  &:active {
    top:0.1em;
  }

  &.selected {
    color: var(--bg-color);
    border-color: black;
    background-color: var(--font-color);
  }
}

input {
  font-family: inherit;
  font-weight: inherit;
  text-transform: initial;
  font-size: 2rem;
  width: 100%;
  padding:0.7em 1.4em;
  border-radius: 3em;
  border: none;
}

#__parcel__error__overlay__ {
  text-transform: initial;
}


